import { LocalizedString } from '../common';

export enum ShowOnFloorplanEnum {
  Default = 0,
  None = 1,
  OnlyCategoryIcon = 2,
  OnlyImage = 5,
  OnlyName = 6,
  CategoryIconAndName = 3,
  CategoryIconAndImage = 4,
  ImageAndName = 7,
}

export enum ShowOnFloorplanWhenActiveDetailEnum {
  Default = 0,
  OnlyCategoryIcon = 2,
  OnlyImage = 5,
  OnlyName = 6,
  CategoryIconAndName = 3,
  CategoryIconAndImage = 4,
  ImageAndName = 7,
}

export enum ShowOnFloorplanWhenActiveRouteEnum {
  Default = 0,
  None = 8,
  Icon = 9,
  Image = 10,
  Name = 11,
}

export interface GeometryPointData {
  type: string;
  coordinates: number[];
}

export interface GeometryLineStringData {
  type: string;
  coordinates: number[][];
}

export interface GeometryPolygonData {
  type: string;
  coordinates: number[][][];
}

export interface GeometryDataInfo {
  type: string;
  coordinates: any;
}

export interface NavigationGeodataObject {
  geometry: GeometryPointData | GeometryLineStringData | GeometryPolygonData | GeometryDataInfo;
  qrCodeId: string;
  qrCategoryColor: string;
  objectsData: ControllableObjectMapItem[];
  showOnFloorplan: ShowOnFloorplanEnum;
  showOnFloorplanAboveIcon: ShowOnFloorplanWhenActiveRouteEnum;
  showOnFloorplanDetail: ShowOnFloorplanWhenActiveDetailEnum;
}

export interface Message {
  type: ControllableObjectMessageType;
  iconUrl: string;
  text: LocalizedString;
  id: string;
}

export interface Item {
  id: string;
  type: string;
  state?: string;
  indication?: string;
  iconUrl?: string;
  label?: LocalizedString;
  value?: LocalizedString;
  valueType?: string;
  unit?: LocalizedString;
  repeatButtonInterval?: number;
}

export interface Group {
  id: string;
  label: LocalizedString;
  message: Message;
  item: Item[];
}

export interface MonitorItem {
  id: string;
  iconUrl?: string;
  label?: LocalizedString;
  value?: LocalizedString;
  unit?: LocalizedString;
}

export enum ControllableObjectMessageType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Nothing = 'nothing',
}

export enum ControllableObjectType {
  Control = 'control-1',
  Monitor = 'monitor-1',
  Indicator = 'indicator-1',
}

export interface ControlableObjectBasicInfo {
  id: string;
  name: string;
  type: ControllableObjectType;
}

export interface ControllableObjectMapItem {
  name: string;
  objectId: string;
  data: ControllableObjectDetail;
  showInDetail: ShowInMapOrDetailModeEnum[];
  showInMap: ShowInMapOrDetailModeEnum[];
}

export enum ShowInMapOrDetailModeEnum {
  Label = 1,
  Control = 2,
  ObjectLabel = 3,
  Icon = 4,
  Value = 5,
  Unit = 6,
  Color = 7,
}

export interface ControllableObjectDetailDataCrate {
  data: ControllableObjectDetail;
  showInDetail: ShowInMapOrDetailModeEnum[];
}

export interface ControllableObjectDetail {
  objectId: string;
  name: string;
  dataFromUnitAvailable: boolean;
  type?: ControllableObjectType;
  version?: number;
  label?: LocalizedString;
  iconUrl?: string;

  //TODO:Jakub rozdelit objektove
  //item pouze pro type Control
  message?: Message;
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Control
  group?: Group[];
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Monitor
  item?: MonitorItem[];
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Indicator
  color?: string;
  //item pouze pro type Indicator
  value?: LocalizedString;
}
